<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      max-height="400"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("utilityNotFoundTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>

        <v-row no-gutters class="mx-4 mt-2" justify="center">
          <v-autocomplete
            v-model="selectedMembersToNotify"
            :items="membersToNotifyList"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="$t('membersToNotify')"
            outlined
            dense
            required
            multiple
            item-text="mbcode_name"
            item-value="mbcode"
            @input="searchMembers = null"
            :search-input.sync="searchMembers"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-text-field
                  v-model="searchMembers"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('searchMembersToNotify')"
                  single-line
                  hide-details
                  flat
                  dense
                  variant="underlined"
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                size="small"
                density="compact"
                v-bind="attrs"
                :input-value="selected"
              >
                <span class="pr-2 chip-content">
                  {{ item.mbcode_name }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-row>
        <v-row no-gutters class="mx-4 mt-2 mb-0" justify="center">
          <v-text-field
            v-model="caller"
            outlined
            dense
            label="User"
            required
            rows="2"
            :error-messages="callerErrors"
            @input="$v.caller.$touch()"
            @blur="$v.caller.$touch()"
          ></v-text-field>
        </v-row>
        <v-row no-gutters class="mx-4 mt-2 mb-0" justify="center">
          <v-text-field
            v-model="utilityType"
            outlined
            dense
            label="Utility Type"
            required
            rows="2"
            @input="utilityType"
          ></v-text-field>
        </v-row>
        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="utilityNotFoundTicketSubmit"
            :disabled="$v.$invalid || submitButtonClicked"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="space-between" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0"
              width="50%"
              depressed
              :disabled="$v.$invalid || submitButtonClicked"
              @click="utilityNotFoundTicketSubmit"
              ><span class="white--text text-none">{{ $t("submit") }}</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations() {
    return {
      caller: { required, minLength: minLength(5), maxLength: maxLength(30) },
      location: { maxLength: maxLength(4096) },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reason: "",
      contact: "",
      menu1: false,
      workDate: "",
      submitButtonClicked: false,
      picker: new Date(),
      startDate: "",
      date: "",
      selectedMembersToNotify: [],
      location: "",
      caller: "",
      utilityType: "",
      searchMembers: null,
    };
  },
  watch: {
    date(val) {
      this.workDate = this.formatDate(this.date);
    },
  },
  methods: {
    checkFacilityType(facility_type) {
      //check if facility type is a 4 character code ending in X
      if (
        !facility_type ||
        (facility_type.length == 4 && facility_type.endsWith("X"))
      ) {
        return false;
      }

      // check to see if facility type is not in the array of facility types that are not required to respond
      const notRequiredFacilityType = ["LOCT", "COPY", "TEST", "ENCR", "PIGB"];
      return !notRequiredFacilityType.includes(facility_type);
    },
    showMember(member) {
      if (
        member.show &&
        member.deliver &&
        this.checkFacilityType(member.facility_type)
      ) {
        return true;
      }
      return false;
    },

    async utilityNotFoundTicketSubmit() {
      console.log("submit btn clicked");
      this.submitButtonClicked = true;

      let utilityNotFoundTicketPayload = {
        ticket_id: this.ticketData.ticket_id,
      };
      let apiData = {
        data: {
          caller: this.caller,
          utilityType: this.utilityType,
        },
      };
      apiData.ticket = this.ticketData.ticket;
      apiData.center = this.ticketData.centerData.center;

      if (
        this.selectedMembersToNotify &&
        this.selectedMembersToNotify.length !== 0
      ) {
        apiData.data.membersToNotify = this.selectedMembersToNotify.join(",");
      } else apiData.data.membersToNotify = "";

      utilityNotFoundTicketPayload.apiData = apiData;

      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionUtilityNotFoundTicket",
        utilityNotFoundTicketPayload
      );

      if (updateTicketResp.status === "error") {
        console.log("Error in Updating Retransmitting Ticket Details");
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else {
        this.$emit(
          "utilityNotFoundTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.closeDialog();
      this.submitButtonClicked = false;
    },
    closeDialog() {
      this.caller = "";
      this.utilityType = "";
      this.remarks = "";
      this.searchMembers = null;
      this.selectedMembersToNotify = [];
      this.submitButtonClicked = false;
      this.$emit("closeUtilityNotFoundTicketDialog");
    },
  },
  computed: {
    membersToNotifyList() {
      let membersList = [];
      if (
        this.ticketData &&
        this.ticketData.receipt &&
        this.ticketData.receipt.members
      ) {
        membersList = this.ticketData.receipt.members.map((member) => {
          if (this.showMember(member)) {
            return {
              mbcode: member.mbcode,
              name: member.name,
              mbcode_name: `${member.mbcode} - ${member.name}`,
            };
          }
        });
      }
      return membersList;
    },

    callerErrors() {
      const errors = [];
      if (!this.$v.caller.$dirty) return errors;
      !this.$v.caller.required && errors.push(this.$t("callerRequired"));
      !this.$v.caller.maxLength && errors.push(this.$t("callerMaxLength"));
      !this.$v.caller.minLength && errors.push(this.$t("callerMinLength"));
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}
.chip-content {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
